<template>
  <main class="container-fluid row m-0 p-0 no-gutters parent bg-neutral-light">
    <section class="m-0 p-0 px-2 col main-area px-4">
      <section>
        <div class="m-3 d-flex align-items-center flex-wrap">
          <span class="text-blue-1 strong-header-text mr-2">
            {{ $store.state.project.name || "Project Name" }}
          </span>
          <v-icon color="#184c9a" size="30px"> mdi-chevron-right </v-icon>
          <span class="strong-header-text font-weight-300">
            {{ steps[currStep].questions[0].systemName }}
          </span>
        </div>
        <hr class="mx-3 mt-2 mb-5" />

        <transition mode="out-in" :name="`slide-${transitionName}`">
          <div :key="currStep">
            <div
              class="mx-3 mb-5"
              v-for="question in steps[currStep].questions"
              :key="question.id"
            >
              <transition name="fade">
                <div v-if="showQuestion(question, question.show)">
                  <div
                    class="header-text wizard-question font-weight-300 text-neutral-dark"
                  >
                    {{ question.text }}
                  </div>
                  <div class="d-flex align-items-center">
                    <v-radio-group
                      v-model="question.answer"
                      :column="
                        !question.answer_options.some((ao) =>
                          ao.text.includes('Yes')
                        )
                      "
                    >
                      <v-radio
                        v-for="option in question.answer_options"
                        color="#184c9a"
                        class="ms-4 mt-3"
                        light
                        :key="option.id"
                        :value="option.id"
                      >
                        <template #label>
                          <div
                            class="d-flex"
                            :class="[
                              !question.answer_options.some((ao) =>
                                ao.text.includes('Yes')
                              )
                                ? 'flex-row'
                                : 'flex-column',
                            ]"
                          >
                            <h2
                              class="ms-3 text-neutral-dark font-weight-300 mb-0"
                            >
                              {{ option.text }}
                            </h2>
                            <div
                              class="rounded-button bg-dark-grey px-3 mx-5"
                              :class="{
                                'bg-primary': question.rooms.length > 0,
                              }"
                              v-if="
                                showSelectRoom(question, option) &&
                                !question.answer_options.some((ao) =>
                                  ao.text.includes('Yes')
                                )
                              "
                              @click="
                                showOverlay = true;
                                currQuestion = question;
                              "
                            >
                              <v-icon color="#fff" size="18px">
                                mdi-checkbox-multiple-marked
                              </v-icon>
                              <span
                                class="subtitle-text text-neutral font-weight-500 ms-1"
                                >Select Spaces</span
                              >
                            </div>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <div
                      class="rounded-button bg-dark-grey px-3 mx-5 mt-3"
                      style="height: max-content"
                      :class="{
                        'bg-primary': question.rooms.length > 0,
                      }"
                      v-if="
                        showSelectRoom(question, null) &&
                        question.answer_options.some((ao) =>
                          ao.text.includes('Yes')
                        )
                      "
                      @click="
                        showOverlay = true;
                        currQuestion = question;
                      "
                    >
                      <v-icon color="#fff" size="18px">
                        mdi-checkbox-multiple-marked
                      </v-icon>
                      <span
                        class="subtitle-text text-neutral font-weight-500 ms-1"
                        >Select Spaces</span
                      >
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div class="wd-30p mx-3" v-if="videoId">
              <h4 class="font-weight-400 max-content">
                Watch how {{ steps[currStep].questions[0].systemName }} works
              </h4>
              <youtube
                width="140%"
                height="100%"
                :video-id="videoId"
                ref="youtube"
                :player-vars="playerVars"
              >
              </youtube>
            </div>
          </div>
        </transition>
      </section>

      <section
        class="position-sticky bottom-0 navigation-border-top bg-neutral-light d-flex justify-content-between py-3 mx-3"
      >
        <button
          @click="prev"
          class="border-neutral-light nav-button rounded-button subtitle-text text-neutral d-flex align-items-center justify-content-center font-weight-300"
        >
          <v-icon color="#fff" size="20px" class="me-1">
            mdi-chevron-left
          </v-icon>
          BACK
        </button>

        <stepper class="stepper" :total="steps.length" :current="currStep" />

        <button
          @click="next"
          class="nav-button rounded-button subtitle-text text-neutral d-flex align-items-center justify-content-center font-weight-300"
        >
          {{ nextText }}
          <v-icon color="#fff" size="20px" class="me-1">
            mdi-chevron-right
          </v-icon>
        </button>
      </section>
    </section>

    <section
      class="z-200"
      :class="['m-0 p-0 col-4 d-none d-md-block', mini ? 'mini' : '']"
    >
      <img
        :src="require(`/src/assets/images/${steps[currStep].image}.png`)"
        alt="tech-bg"
        class="img-section"
      />
    </section>

    <modal @close="showOverlay = false" :show="showOverlay" :hide-close="true">
      <h3
        class="max-content mx-auto select-room-title mb-4 position-relative font-weight-300"
      >
        Select Spaces
      </h3>
      <div class="container p-0">
        <div class="room-grid gap-3">
          <project-space
            class="wd-45p-f wd-md-20p-f"
            v-for="room in rooms"
            :is-selected="isSelected(room)"
            :selected="0"
            :name="room.name"
            :type-name="room.typeName"
            :type-value="room.typeValue"
            @click.native="chooseRoom(room)"
            :key="room.id"
            disable-count
          />
          <square-container
            @click.native="showAddRoomModal = true"
            class="wd-45p-f wd-md-20p-f add-room-button cursor-pointer bg-primary-light add-room"
          >
            <div class="d-flex flex-column h-100 justify-content-center">
              <v-icon color="#fff" size="5rem"> mdi-plus </v-icon>
              <h5 class="mt-3" style="color: #fff">Add Space</h5>
            </div>
          </square-container>
        </div>
      </div>

      <section
        class="position-sticky bottom-0 navigation-border-top mt-4 d-flex justify-content-between py-3 mx-3 wd-50p mx-auto"
      >
        <button
          class="border-neutral-light nav-button subtitle-text text-neutral d-flex align-items-center justify-content-center bg-black font-weight-300"
          @click="cancelSelectRoom"
        >
          CANCEL
        </button>

        <button
          class="nav-button subtitle-text text-neutral d-flex align-items-center justify-content-center bg-blue-2 font-weight-300"
          @click="handleOk"
        >
          OK
        </button>
      </section>
      <p
        class="text-danger font-weight-bold"
        :class="textShake ? 'shakeAnimation' : ''"
        style="font-size: 1.2rem"
        v-if="roomSelectedError"
      >
        You have to at least select one space
      </p>
    </modal>

    <add-room-modal v-model="showAddRoomModal" />
    <cart-loading v-if="showCartLoading" @loading-done="cartLoadingDone" />
  </main>
</template>

<script>
import Stepper from "../components/Stepper.vue";
import ProjectSpace from "../components/ProjectSpace.vue";
import Modal from "@/components/Modal";
import SquareContainer from "@/components/SquareContainer";
import AddRoomModal from "@/components/AddRoomModal";
import { getIdFromUrl } from "vue-youtube";
import CartLoading from "@/components/CartLoading";

export default {
  name: "Wizard",
  components: {
    CartLoading,
    AddRoomModal,
    SquareContainer,
    Modal,
    Stepper,
    ProjectSpace,
  },
  props: ["limitedQuestionIds"],
  data() {
    return {
      currStep: 0,
      currQuestion: null,
      showOverlay: false,
      showAddRoomModal: false,
      roomSelectedError: false,
      textShake: false,
      playerVars: {
        autoplay: 1,
      },
      showCartLoading: false,
      transitionName: "left",
    };
  },
  methods: {
    handleOk() {
      if (!this.rooms.some(this.isSelected)) {
        this.roomSelectedError = true;
        this.textShake = true;
        setTimeout(() => {
          this.textShake = false;
        }, 1000);
        return;
      } else {
        this.roomSelectedError = false;
      }
      this.showOverlay = false;
    },
    next() {
      this.transitionName = "left";

      if (this.currStep < this.steps.length - 1) {
        this.currStep++;
      } else {
        if (!this.user) {
          this.$emit("signup");
        } else {
          this.showCartLoading = true;
        }
      }
    },
    cartLoadingDone() {
      this.$store.dispatch("wizard/submit");
    },
    prev() {
      this.transitionName = "right";

      if (this.currStep > 0) this.currStep--;
    },
    cancelSelectRoom() {
      this.switchAnswerToNo();
      let questions = this.steps[this.currStep].questions.filter(
        (q) => !q.wizard_select_rooms || q.id === this.currQuestion.id
      );

      questions.forEach((q) => {
        q.rooms.forEach((r) => {
          this.$store.commit("wizard/removeRoomFromAnswer", {
            questionId: q.id,
            roomId: r,
          });
        });
      });

      setTimeout(() => {
        this.showOverlay = false;
      }, 0);
    },
    chooseRoom(room) {
      let questions = this.steps[this.currStep].questions.filter(
        (q) => !q.wizard_select_rooms || q.id === this.currQuestion.id
      );
      if (this.isSelected(room)) {
        questions.forEach((q) => {
          this.$store.commit("wizard/removeRoomFromAnswer", {
            questionId: q.id,
            roomId: room.id,
          });
        });
      } else {
        questions.forEach((q) => {
          this.$store.commit("wizard/addRoomToAnswer", {
            questionId: q.id,
            roomId: room.id,
          });
        });
      }
      this.$forceUpdate();
    },
    switchAnswerToNo() {
      this.steps[this.currStep].questions[0].answer = this.steps[
        this.currStep
      ].questions[0].answer_options.find((a) => a.text === "No").id;
    },
    isSelected(room) {
      return this.currQuestion?.rooms?.includes(room.id);
    },
    showSelectRoom(question, answer) {
      return (
        question.wizard_select_rooms &&
        question.answer_options.find((ao) => ao.id === question.answer)
          ?.positive &&
        (answer ? question.answer === answer.id : true)
      );
    },
    showQuestion(question, previousState) {
      if (!question.show_on_answer_option_id) return true;

      let result = this.steps[this.currStep].questions.some(
        (q) => q.answer === question.show_on_answer_option_id
      );

      question.show = result;

      if (previousState && !result) {
        question.answer = null;
      }

      return result;
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    videoId() {
      if (!this.steps[this.currStep].questions[0].systemCategory) {
        return null;
      }

      return getIdFromUrl(
        this.$store.state.dashboard.systemTutorials[
          this.steps[this.currStep].questions[0].systemCategory
        ]?.tutorialUrl
      );
    },
    mini: function () {
      return this.$route.meta?.mini;
    },
    rooms: function () {
      return this.$store.state.dashboard.rooms.filter(
        (r) => r.typeName !== "Backend"
      );
    },
    steps() {
      if (this.limitedQuestionIds && this.limitedQuestionIds.length > 0) {
        return this.$store.state.wizard.wizardSteps.filter((s) =>
          s.questions.some((q) => this.limitedQuestionIds.includes(q.id))
        );
      } else {
        return this.$store.state.wizard.wizardSteps;
      }
    },
    nextText() {
      if (this.currStep < this.steps.length - 1) {
        return "NEXT";
      }

      return "SAVE";
    },
  },
  watch: {
    steps: {
      handler: function (newVal) {
        if (
          newVal[this.currStep].questions[1].answer &&
          this.showSelectRoom(newVal[this.currStep].questions[1], null)
        ) {
          this.showOverlay = true;
          this.currQuestion = newVal[this.currStep].questions[1];
        }
        this.$store.commit(
          "project/updateName",
          this.$store.state.project.name
        );
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.shakeAnimation {
  animation: shake 0.8s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(25px);
  }

  50% {
    transform: translateX(-25px);
  }
  100% {
    transform: translateX(0px);
  }
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 600ms ease-in-out;
}
.slide-left-enter-from {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 600ms ease-in-out;
}
.slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms linear;
  transition-property: opacity, transform;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.navigation-border-top {
  border-top: 1px solid var(--grey);
}

.navigation-border-bottom {
  border-bottom: 1px solid var(--grey);
}

.add-room-button {
  background: white;
  border: 2px solid var(--neutral-dark);
  border-radius: 10px;
}

.wizard-question {
  line-height: 1.2;
  font-size: 2.2rem;
  margin-bottom: -16px;
}

.responsive-image {
  overflow: hidden;
  max-width: 300px;
  @media (max-width: 1100px) {
    display: none;
  }
}

.add-room {
  &:hover {
    background: var(--primary) !important;
  }
}

.main-area {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.parent {
  background-color: white;
}

.stepper {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.img-section {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: backgroundScroll ease-in-out 35s infinite;
}

@keyframes backgroundScroll {
  0% {
    object-position: 0;
  }
  50% {
    object-position: 100%;
  }

  100% {
    object-position: 0;
  }
}

.mini {
  max-width: 20%;
}

.close-btn {
  /* position: absolute;
  top: 10px;
  right: 20px; */
  margin: 30px;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-button {
  width: 120px;
  height: 40px;
  border-radius: 40px;
}

.select-room-title {
  color: var(--neutral-light);
  font-size: 3rem;
  margin-top: 10rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.room-grid {
  width: 100%;
  justify-content: center;

  @media (min-width: 768px) {
    width: 60%;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
